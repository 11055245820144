import "core-js/modules/es.array.push.js";
import * as local from '@/libs/local';
//import HelloWorld from '@/components/HelloWorld.vue';
import { showToast } from 'vant';
import Vue from 'vue';
import * as api from '@/api';
import { ref } from 'vue';
import { Tab, Tabs } from 'vant';
import { Col, Row } from 'vant';
import { Popup } from 'vant';
import { Cell, CellGroup } from 'vant';
import { Dialog } from 'vant';
import { NoticeBar } from 'vant';
import { NavBar } from 'vant';
import { Picker } from 'vant';
import { Empty } from 'vant';
import { BackTop } from 'vant';
import { Notify } from 'vant';
import { Uploader } from 'vant';
import 'vant/es/toast/style';
import 'vant/es/dialog/style';
// 引入英文语言包

import Header from '../lyout/header.vue';
import Footer from '../lyout/footer.vue';
import Ce from '../lyout/ce.vue';
export default {
  name: 'Home',
  components: {
    Header,
    Footer,
    Ce
  },
  data() {
    return {
      active: 0,
      lang_list: {},
      select_lang: {},
      lang: {},
      cate: [],
      user: {},
      pass: '',
      pass1: '',
      pass2: '',
      zf_pass: '',
      zf_pass1: '',
      zf_pass2: ''
    };
  },
  created: function () {
    let _this = this;
    _this.get_imdex_data();
    this.code = this.$route.query.code ? this.$route.query.code : '0000';
  },
  methods: {
    onClickLeft() {
      api.chat(0);
    },
    back() {
      window.history.back();
    },
    //切换语言
    tab_lang(lang) {
      local.saveInfo('setting_lang', lang);
      window.location.reload();
    },
    afterRead: function (file) {
      // 此时可以自行将文件上传至服务器
      console.log(file);
      // if (this.fileList.length > 1) {
      //   this.$toast.fail('Only upload an image');
      //   this.fileList.splice(1);
      //   return;
      // }
      //  let _this=this
      let fd = new FormData(); // 实例化表单，提交数据使用
      fd.append('type', 'material');
      fd.append('file', file.file); // 将img追加进去
      // fd.append('token', local.readInfo('jwt'));
      api.all('/api/user/upload', fd, (err, data) => {
        //toast1.clear();
        if (data.code == 0) {
          showToast('Picture is too big');
        } else {
          console.log(err);
          console.log(data);
          this.user.head = data.data.url;
        }
      }, {
        onUploadProgress: progressEvent => {
          console.log(progressEvent);
        }
      });
    },
    onOversize(file) {
      console.log(file);
      showToast(this.lang.t6);
    },
    get_imdex_data: function () {
      api.all('/api/user/user_pass', {}, (err, data) => {
        if (!err && data.code === 1) {
          this.lang_list = data.lang_list;
          this.select_lang = data.select_lang;
          this.lang = data.data.lang;
          this.cate = data.data.cate;
          this.user = data.data.user;
        } else {
          console.log(data);
        }
      });
    },
    up: function (type) {
      api.all('/api/user/user_pass', {
        pass: this.pass,
        pass1: this.pass1,
        pass2: this.pass2,
        zf_pass: this.zf_pass,
        zf_pass1: this.zf_pass1,
        zf_pass2: this.zf_pass2,
        type: type
      }, (err, data) => {
        showToast(data.msg);
      });
    },
    detail: function (id1) {
      let _this = this;
      _this.$router.push({
        name: 'detail',
        query: {
          id: id1
        }
      });
    },
    chat: function (type = 0) {
      api.chat(type);
    }
  }
};